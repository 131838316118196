import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Icons from "../components/icons"
import Contact from "../components/contact"

import BackgroundImage from "gatsby-background-image"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "home-image-v2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      homeLevel5Img: file(relativePath: { eq: "home/home-lvl-5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      homeLevel6Img: file(relativePath: { eq: "home/home-lvl-6.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <BackgroundImage
        Tag="div"
        className="bodhi__main-image"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <div className="container-fluid bodhi__main-image-container">
          <h1>NZQA Approved Tertiary Study in Early Childhood Education.</h1>
          <h4>Now taking applications for 2025 intakes.</h4>
          <Link to="/enrol" className="btn">
            Apply Now
          </Link>
        </div>
      </BackgroundImage>
      <div id="features" className="home__features bodhi__bg-pattern">
        <div className="container">
          <div className="row home__three-col-layout">
            <div className="col-sm-4 text-center">
              <Icons type="study" width="60" height="52" />
              <h3>Supportive Learning Environment</h3>
            </div>
            <div className="col-sm-4 text-center">
              <Icons type="computer-desk" width="60" height="52" />
              <h3>Study From Home</h3>
            </div>
            <div className="col-sm-4 text-center">
              <Icons type="heart" width="60" height="52" />
              <h3>Core Values Based</h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row home__border-bottom">
            <div className="col-sm-12 text-center">
              <h3>NZQA Approved Tertiary Study in Early Childhood Education</h3>
              <p>
                Bodhi provides hands-on tertiary training to students looking to
                gain professional qualification in Early Childhood Education and
                Care (ECE). Created by passionate early childhood educators, Kim
                & Kerry, our vision is for students to develop a high level of
                understanding and respect for newborns, infants and toddlers,
                which will result in a raised level of care and education for
                children throughout Aotearoa.
              </p>
              <p>
                Bodhi currently has NZQA approval for two programmes, the{" "}
                <b>
                  New Zealand Diploma in Early Childhood Education and Care,
                  Level 5 & 6.
                </b>
              </p>

              <p>
                The Government has announced that Fees Free for the first year
                of study or training will finish at the end of 2024. A
                final-year Fees Free policy will replace it, starting from
                January 2025 with the following parameters:
              </p>
              <p>
                &#8226; Learners entering their final year of study or training
                from 1 January 2025 and who have not received first-year Fees
                Free may be eligible for the new final-year Fees Free policy.
              </p>
              <p>
                &#8226;Eligibility will include provider- and work-based study
                or training at Levels 3 and above on the New Zealand
                Qualifications and Credentials Framework.
              </p>
              <p>
                &#8226;Repayment will be made after the learner completes their
                qualification, with refunds starting from 2026.
              </p>

              <p>
                You can{" "}
                <a href="https://www.feesfree.govt.nz/" target="blank">
                  check your eligibility here
                </a>
                .
              </p>
              <p>
                Bodhi is guided by Education (Pastoral Care of Tertiary and
                International Learners) Code of Practice 2021.To learn more
                about the Code and to view Bodhi's most recent Self Review
                please <Link to="/code-of-practise">click here.</Link>
              </p>
            </div>
          </div>
        </div>
        {/** Contact form */}
        <Contact />
        <div className="container">
          <div className="row feature">
            <div className="col-sm-6 pic-right">
              <Img
                fluid={data.homeLevel5Img.childImageSharp.fluid}
                alt="Mum and Baby Cuddle"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-6 info info-left">
              <h3>
                New Zealand Diploma in Early Childhood Education and Care (Level
                5).
              </h3>
              <p>
                <b>Full-Time Study.</b> Total: 120 Credits | 40 Weeks
              </p>
              <p>
                This 40 week course is designed for students who want a career
                in a licensed early learning setting. The course is delivered
                online so students get to study from the comfort of their own
                home. The practical component must be completed in a licensed
                early childhood setting, this ensures that if a student decides
                to pathway, they will be eligible for Teacher Registration upon
                graduation with a Bachelor of Teaching (ECE).
              </p>
              <p>
                <b>Applications Open for: February 2025 Intake.</b>
              </p>
            </div>
          </div>
          <div className="row feature">
            <div className="col-sm-6">
              <Img
                fluid={data.homeLevel6Img.childImageSharp.fluid}
                alt="Baby With Glasses"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-6 info">
              <h3>
                New Zealand Diploma in Early Childhood Education and Care (Level
                6).
              </h3>
              <p>
                <b>Full-Time Study.</b> Total: 120 Credits | 40 Weeks
              </p>
              <p>
                This 40 week course deepens students knowledge base and
                understanding from the previous year. All students must have
                completed the New Zealand Diploma in Education and Care (Level
                5) as a pre-requisite.
              </p>
              <p>
                Bodhi aims to provide its students with the opportunity to gain
                the Bachelor of Teaching (ECE) so our students can cross-credit
                and graduate with this qualification which can lead to teacher
                registration.
              </p>
              <p>
                <b>Applications Open for: February 2025 Intake.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
